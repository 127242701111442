
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Provider } from 'react-redux';
import { FeatureDefinition } from '@growthbook/growthbook';
import MuiStyledEngineProvider from '@mui/material/StyledEngineProvider';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import type { SetupWorkerApi } from 'msw';
import type { AppProps } from 'next/app';
import { Roboto } from 'next/font/google';
import { GrowthBookProvider } from '@/context/Growthbook';
import { useAppSelector } from '@/hooks';
import { onboardingActions } from '@/redux/features/onboardingSlice';
import { Store, wrapper } from '@/redux/store';
import { Loader } from '@/shared/Loader';
import { RenderIf } from '@/shared/RenderIf';
import { themeOptions } from '../theme/material-theme-options';
import '../styles/globals.scss';
import '../styles/theme/main.scss';
type GenericAppProps<P = unknown> = {
    pageProps: P;
} & Omit<AppProps<P>, 'pageProps'>;
export type MyAppProps = {
    growthBookFeatures: Record<string, FeatureDefinition>;
};
type WrappedStoreReturn = {
    store: Store;
    props: GenericAppProps<MyAppProps>;
};
const roboto = Roboto({
    weight: ['300', '400', '500', '700'],
    subsets: ['latin'],
});
const MyApp: FC<GenericAppProps<MyAppProps>> = ({ Component, ...rest }) => {
    const { store, props: { pageProps } } = wrapper.useWrappedStore(rest) as WrappedStoreReturn;
    const [isMockServerUp, setIsMockServerUp] = useState(false);
    /* Memoize the theme to avoid unnecessary recalculations */
    const theme = useMemo(() => createTheme(themeOptions), []);
    useEffect(() => {
        let browserWorker: SetupWorkerApi | null = null;
        const initMockServer = async () => {
            const { getBrowserWorker } = await import('@/tests/mocks/server.browser.mock');
            browserWorker = getBrowserWorker();
            browserWorker.start().then(() => setIsMockServerUp(true));
        };
        if (process.env.NEXT_PUBLIC_MOCK_SERVER === 'on') {
            initMockServer();
        }
        return () => {
            if (browserWorker) {
                browserWorker.stop();
            }
        };
    }, []);
    useEffect(() => {
        // Expose some app data to Cypress for testing
        if (window.Cypress) {
            window.redux = {
                store,
                onboardingActions: onboardingActions,
            };
        }
    }, [store]);
    return (<>
      <style jsx global>{`
        html {
          font-family: ${roboto.style.fontFamily};
        }
      `}</style>
      <RenderIf condition={process.env.NEXT_PUBLIC_MOCK_SERVER !== 'on'
            || (process.env.NEXT_PUBLIC_MOCK_SERVER === 'on' && isMockServerUp)}>
        <Provider store={store}>
          <MuiStyledEngineProvider injectFirst>
            <MuiThemeProvider theme={theme}>
              <GrowthBookProvider features={pageProps.growthBookFeatures}>
                <LoaderProvider>
                  <Component {...pageProps}/>
                </LoaderProvider>
              </GrowthBookProvider>
            </MuiThemeProvider>
          </MuiStyledEngineProvider>
        </Provider>
      </RenderIf>
    </>);
};
const LoaderProvider: FC<PropsWithChildren> = ({ children }) => {
    const { hasLoader } = useAppSelector(state => state.onboarding);
    return (<>
      {children}
      <Loader show={hasLoader}/>
    </>);
};
const __Next_Translate__Page__195b6de5fd6__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__195b6de5fd6__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  