import { ChangeEvent, FC, MouseEvent } from 'react';
import { css, Theme } from '@emotion/react';
import { MenuItem } from '@mui/material';
import MuiButton from '@mui/material/Button';
import MuiButtonGroup from '@mui/material/ButtonGroup';
import { ButtonGroupProps as MuiButtonGroupProps } from '@mui/material/ButtonGroup/ButtonGroup';
import useTranslation from 'next-translate/useTranslation';

import { TextField } from '@/shared/TextField';
import { AccessLevel } from '@/types/invite-member.type';


type AccessLevelPickerProps = {
  onAccessLvlChange: (role: AccessLevel) => void;
  accessLvlValue: AccessLevel;
  variant?: 'select' | 'buttons';
}

type AccessLvlBtn = {
  accessLvl: AccessLevel;
  btnText: string;
}

const accessLvlButtons: AccessLvlBtn[] = [
  { accessLvl: 'admin', btnText: 'Admin' },
  { accessLvl: 'manager', btnText: 'Manager' },
  { accessLvl: 'user', btnText: 'User' },
];

const accessLvlButtonsSelector: AccessLvlBtn[] = [...accessLvlButtons];
accessLvlButtonsSelector.splice(2, 1, { accessLvl: 'user', btnText: 'Regular User' });

const styles = {
  TextField: (theme: Theme) => css`
    min-width: 192px;

    ${ theme.breakpoints.down('sm') } {
      width: 100%;
    }
  `,
};

export const AccessLevelPicker: FC<AccessLevelPickerProps> = ({ onAccessLvlChange, accessLvlValue, variant }) => {
  const handleAccessLvlChange = (e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLDivElement>) => {
    const newAccessLvl = (e.target as HTMLInputElement).value as AccessLevel;
    onAccessLvlChange(newAccessLvl);
  };

  return variant === 'select'
    ? <RoleSelector currentAccessLvl={ accessLvlValue } onChangeAccessLvl={ handleAccessLvlChange } />
    : <RoleButtons currentAccessLvl={ accessLvlValue } onClick={ handleAccessLvlChange } fullWidth />;
};


const RoleButtons: FC<MuiButtonGroupProps & { currentAccessLvl: AccessLevel }> =
  ({ currentAccessLvl, ...btnGroupProps }) => (
    <MuiButtonGroup { ...btnGroupProps } fullWidth>
      {
        accessLvlButtons.map(({ accessLvl, btnText }) => (
          <MuiButton
            key={ accessLvl }
            value={ accessLvl }
            aria-selected={ accessLvl === currentAccessLvl }
            fullWidth
          >
            { btnText }
          </MuiButton>
        ))
      }
    </MuiButtonGroup>
  );

const RoleSelector: FC<{
  currentAccessLvl: AccessLevel,
  onChangeAccessLvl: (e: ChangeEvent<HTMLInputElement>) => void
}> = ({ currentAccessLvl, onChangeAccessLvl }) => {
  const { t } = useTranslation();
  return (
    <TextField
      select
      label={ t('onboarding:invite_members_step.access_level') }
      value={ currentAccessLvl }
      onChange={ onChangeAccessLvl }
      variant="filled"
      css={ styles.TextField }
      InputLabelProps={{
        sx: {
          color: '#9A918D !important',
        },
      }}
      InputProps={{
        sx: {
          '& .MuiSelect-icon': {
            color: '#574841',
          },
        },
      }}
    >
      { accessLvlButtonsSelector.map(({ accessLvl, btnText }) => (
        <MenuItem key={ accessLvl } value={ accessLvl }>
          { btnText }
        </MenuItem>
      )) }
    </TextField>
  );
};
