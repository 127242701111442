import MuiLoadingButton, { LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

import { skipPropForwardingFn } from '../../theme/utils.theme';

import { AdditionalButtonProps } from '.';

export type LoadingButtonProps = MuiLoadingButtonProps & AdditionalButtonProps;

export const LoadingButton = styled(MuiLoadingButton, {
  shouldForwardProp: (prop) =>
    skipPropForwardingFn<AdditionalButtonProps>(
      ['rounded', 'noHover'],
      prop,
    ),
})<LoadingButtonProps>((
  {
    theme,
    noHover = false,
    rounded = false,
    uppercase = true,
    variant,
  },
) => ({
  padding: '8px 16px',
  borderRadius: 4,
  lineHeight: '20px',
  textTransform: uppercase ? 'uppercase' : 'none',
  ...(noHover && {
    '&.MuiButton-root:hover': {
      backgroundColor: 'initial',
    },
  }),
  ...(rounded && { borderRadius: '26px' }),
  ...(variant === 'contained' && {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.secondary.light,
      color: '#B3AEAB',
    },
  }),
}));
