import type { ThemeOptions } from '@mui/material/styles';
import { Roboto } from 'next/font/google';

import { breakpoints } from './breakpoints.theme';
import { utils } from './utils.theme';

const roboto = Roboto({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
});

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#02AB50',
      dark: '#24B464',
      light: 'rgba(39, 194, 108, 0.07)',
      contrastText: '#fff',
    },
    secondary: {
      main: '#574841',
      contrastText: '#fff',
      light: '#5748411A',
    },
    neutral: {
      main: '#a4a7b2',
      dark: '#6D6F78',
      light: '#F1EFED',
      lighter: '#DDDBD9',
      contrastText: '#574841',
    },
    info: {
      main: '#574841',
      dark: '#000000',
      light: '#e0e0e0',
      lighter: '#eeeef0',
      contrastText: '#574841',
    },
    error: {
      main: '#f22f42',
    },
  },
  typography: {
    fontFamily: roboto.style.fontFamily,
    h1: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: '37.5px',
      textAlign: 'center',
      color: '#000',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 300,
      lineHeight: '57px',
      textAlign: 'center',
      '@media (max-width:540px)': {
        fontSize: '2rem',
        lineHeight: '37.5px',
      },
    },
    h3: {
      fontSize: '2.125rem',
      fontWeight: 700,
      lineHeight: '40px',
      letterSpacing: '0.25px',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: '32px',
      '@media (max-width:540px)': {
        fontSize: '1.125rem',
        lineHeight: '26px',
      },
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '28px',
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '28px',
      letterSpacing: '0.14px',
      '@media (max-width:1023px)': {
        fontSize: '1rem',
        lineHeight: '24px',
      },
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#6d6f78',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      '.bold': {
        fontWeight: 700,
      },
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.1px',
      '&.bold': {
        fontWeight: 700,
      },
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.75px',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '0.4px',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true, // By default, we don't use elevation on buttons!
        size: 'large',
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: 'p',
          body2: 'p',
        },
      },
    },
  },
  breakpoints,
  utils,
};
