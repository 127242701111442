import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useGrowthBook } from '@growthbook/growthbook-react';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';
import MuiTypography from '@mui/material/Typography';
import classNames from 'classnames';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';

import { useAppDispatch, useDialog, useOnboardingTask, useTracking, useTraits } from '@/hooks';
import { selectCompanyId } from '@/redux/features/authSlice';
import { setTrackingMode } from '@/redux/features/onboardingSlice';
import { BasicCard } from '@/shared/BasicCard';

import CompanyRequests from '../../../requests/company.requests';
import { CompanyTrackingMode, StepCommonProps, StepInfo, TrackingModeStep } from '../../../types';

import TrackingSilentInteractiveContent, { theme } from './TrackingSilentInteractiveContent';

import styles from '../ComputerOwnerStep/ComputerOwnerStep.module.scss';

const stepData = [
  {
    key: 'visible',
    title: 'onboarding:tracking_mode_step.interactive_card.title',
    desc: 'onboarding:tracking_mode_step.interactive_card.desc',
  },
  {
    key: 'silent',
    title: 'onboarding:tracking_mode_step.silent_card.title',
    desc: 'onboarding:tracking_mode_step.silent_card.desc',
  },
];

const TrackingModeStepComponent: FC<StepCommonProps> = ({ flow }) => {
  const { t } = useTranslation();
  const growthBook = useGrowthBook();
  const dispatch = useAppDispatch();
  const { trackOnboardingStep } = useTracking(flow);
  const { goToNextStep, getNextStep } = useOnboardingTask(flow);
  const { updateTraits } = useTraits();
  const companyId = useSelector(selectCompanyId);

  const handleCardClicked = async (cardKey: string) => {
    const trackingMode: CompanyTrackingMode = cardKey === 'visible' ? 'interactive' : 'silent';
    const tasksMode = trackingMode === 'interactive' ? 'preset' : 'off';
    flow.trackingMode = trackingMode;

    growthBook?.setAttributes({
      ...growthBook?.getAttributes(),
      tracking_mode: trackingMode,
    });

    trackOnboardingStep(
      'Tracking Type Selected Option',
      { trackingtype: trackingMode, ownership: 'company' },
    );
    CompanyRequests.putCompanySettings(companyId, { trackingMode, tasksMode });

    if (trackingMode === 'silent') onSilentTracking();
    else onInteractiveTracking();
  };

  const onSilentTracking = async () => {
    dispatch(setTrackingMode('silent'));
    updateTraits({
      companyTraits: { taskMode: 'off' },
    });

    await goToNextStep({ skipNext: true });
  };

  const onInteractiveTracking = () => {
    const nextStep = getNextStep();
    updateTraits({
      companyTraits: { onboarding_step: nextStep?.stepName, taskMode: 'preset' },
    });

    dispatch(setTrackingMode('interactive'));
    goToNextStep();
  };

  return (
    <div className={ classNames(styles.ComputersStep, 'd-flex') }>
      {
        stepData.map(data => (
          <BasicCard
            key={ data.key } cardKey={ data.key }
            onCardClicked={ handleCardClicked }
            aria-label={ t(data.title) }
          >
            <h5 className="title | ft-size-16 color-pure-black txt-left">{ t(data.title) }</h5>
            <p className="subtitle | ft-size-12 color-secondary-brown">{ t(data.desc) }</p>
          </BasicCard>
        ))
      }
    </div>
  );
};

const InfoComponent: FC = () => {
  const { Dialog, showDialog } = useDialog();
  const { trackAppEvent } = useTracking();
  const { t } = useTranslation();

  const handleClick = () => {
    trackAppEvent('Clicked Learn More Onboarding', { category: 'Onboarding' });
    showDialog();
  };

  return (
    <MuiThemeProvider theme={ theme }>
      <Trans
        i18nKey="onboarding:tracking_mode_step.info"
        components={{
          b: <span className="color-primary" style={{ cursor: 'pointer' }} onClick={ handleClick } />,
        }}
      />
      <Dialog>
        {{
          title: <MuiTypography variant="h4" className="pr-sm8-4">
            { t('onboarding:tracking_mode_step.silent_vs_interactive') }
          </MuiTypography>,
          content: <TrackingSilentInteractiveContent />,
        }}
      </Dialog>
    </MuiThemeProvider>
  );
};

export const TrackingModeStepInfo: StepInfo<TrackingModeStep> = {
  Component: TrackingModeStepComponent,
  id: 'trackingModeStep',
  stepName: 'Tracking Type Screen',
  title: 'onboarding:tracking_mode_step.title',
  desc: 'onboarding:tracking_mode_step.description',
  Info: <InfoComponent />,
};
