import React, { createContext, Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useFeature } from '@growthbook/growthbook-react';

import { InviteFormComponent } from '@/components/Steps/InviteTeamStep/InviteFormComponent';
import { InviteSelectionComponent } from '@/components/Steps/InviteTeamStep/InviteSelectionComponent';
import { useAppSelector, useTracking } from '@/hooks';
import { FlowModel } from '@/models/flow.model';
import CompanyRequests from '@/requests/company.requests';
import { ExperimentProps, StepNames } from '@/types/index';

export const InviteStepCtx = createContext<{
  didCopyLink: boolean, setDidCopyLink: Dispatch<SetStateAction<boolean>>
}>(
  {
    didCopyLink: false,
    setDidCopyLink: () => ({}),
  },
);

export const InviteTeamStep: FC<{ flow: FlowModel }> = ({ flow }) => {
  const shouldShowExpandableInviteScreenExp = useFeature<ExperimentProps>('expandable-invite-screen');
  const expandableInviteStep = shouldShowExpandableInviteScreenExp.value?.variationid as 'A' | 'B' | undefined;
  const [didCopyLink, setDidCopyLink] = useState(false);
  const { trackStepChangePage, trackStepChangeEvent } = useTracking(flow);
  const hasTracked = useRef(false);
  const sendInvitesStepExpandable: StepNames = 'Expandable Invite Employees Screen';
  const sendInvitesStep: StepNames = 'Invite Employees Screen';
  const { company } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (hasTracked.current || expandableInviteStep === undefined) return;
    updateCompanySettings(expandableInviteStep);
    trackStepPageAndEvent(expandableInviteStep);
    hasTracked.current = true;

  }, [expandableInviteStep]);

  /**
   * Tracks step change events and page view based on the invite step variant.
   */
  const trackStepPageAndEvent = (variant: string) => {
    const stepName = variant === 'B' ? sendInvitesStepExpandable : sendInvitesStep;

    trackStepChangePage(stepName);
    trackStepChangeEvent(flow.trackingMode ?? null, { stepName });
  };

  /**
   * Updates the company settings, ensuring existing data is preserved.
   */
  const updateCompanySettings = (variant: string) => {
    if (!company) return;

    CompanyRequests.putCompanySettings(company.id, {
      custom: {
        ...company.companySettings?.custom,
        onboardingStep: 'sendInvitesStep',
        expandableInviteVariant: variant,
      },
    });
  };
  return (
    <InviteStepCtx.Provider value={{ didCopyLink, setDidCopyLink }}>
      { expandableInviteStep === 'B' ? (
        <InviteSelectionComponent flow={ flow } experimentVariant={ expandableInviteStep } />
      ) : (
        <InviteFormComponent flow={ flow } experimentVariant={ expandableInviteStep } />
      ) }
    </InviteStepCtx.Provider>
  );
};
