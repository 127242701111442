import React, { FC, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';
import classNames from 'classnames';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';

import { useOnboardingTask, useTracking } from '@/hooks';
import { BasicCard } from '@/shared/BasicCard';
import { Button, ButtonProps } from '@/shared/Button';
import { FlexBox } from '@/shared/FlexBox';
import { StepCommonProps } from '@/types/steps.type';

import { injectDownloadService } from '../../../services/download.service';
import DeviceAndGeoDetector from '../../../utils/device-detector.utils';

import styles from './DownloadApssStep.module.scss';

interface DownloadAppsStepComponentProps extends StepCommonProps {
  variantRef?: React.RefObject<string | undefined>;
}

export const DownloadAppsStepComponent: FC<DownloadAppsStepComponentProps> = ({ flow }) => {
  const { t } = useTranslation();
  const { onCompletedOnboarding } = useOnboardingTask(flow);
  const { trackOnboardingStep, trackAppEvent } = useTracking();
  const downloadService = useRef(injectDownloadService());
  const browserAppDownloadUrl = 'https://chromewebstore.google.com/detail/fpfkmegplkpabeceoljjeajajeoiimim';
  const [appDesktopDownloadUrl, setAppDesktopDownloadUrl] = useState('');

  useEffect(() => {
    if (!DeviceAndGeoDetector.isChromeBrowser()) onCompletedOnboarding();

    const fetchDownloadData = async () => {
      const downloadData = await downloadService.current.getDownloadData();
      setAppDesktopDownloadUrl(downloadData?.href || '');
    };

    fetchDownloadData();
  }, []);

  const handleSkipBtnClick = async () => {
    await trackOnboardingStep('Skipped This Step On preferred Time Doctor App Screen');
    onCompletedOnboarding();
  };

  const handleDownload = async () => {
    trackAppEvent('Clicked Desktop App Install Button', { location: 'onboarding' });

    const link = document.createElement('a');
    link.href = appDesktopDownloadUrl;
    link.setAttribute('download', appDesktopDownloadUrl);
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
      onCompletedOnboarding();
    }, 1500);
  };

  const handleBrowserAppDownload = () => {
    trackAppEvent('Clicked Browser App Install Button', { location: 'onboarding' });
    window.open(browserAppDownloadUrl, '_blank');
    setTimeout(() => {
      onCompletedOnboarding();
    }, 1500);
  };

  const cardData = [
    {
      key: 'browserApp',
      imageSrc: '/assets/onboarding/downloadApps/chrome.svg',
      title: 'onboarding:download_apps_step.browser_app.title',
      features: [
        'onboarding:download_apps_step.browser_app.features.feature1',
        'onboarding:download_apps_step.browser_app.features.feature2',
        'onboarding:download_apps_step.browser_app.features.feature3',
      ],
      buttonText: 'onboarding:download_apps_step.browser_app.button',
      buttonStyle: {
        backgroundColor: '#27C26C',
        border: 'none',
        color: '#fff',
      },
      downloadFunction: handleBrowserAppDownload,
    },
    {
      key: 'desktopApp',
      imageSrc: '/assets/onboarding/downloadApps/desktop.svg',
      title: 'onboarding:download_apps_step.desktop_app.title',
      features: [
        'onboarding:download_apps_step.desktop_app.features.feature1',
        'onboarding:download_apps_step.desktop_app.features.feature2',
        'onboarding:download_apps_step.desktop_app.features.feature3',
      ],
      buttonText: 'onboarding:download_apps_step.desktop_app.button',
      buttonStyle: {
        backgroundColor: '#FFF',
        border: '#DCDDE1 1px solid',
        color: '#27C26C',
      },
      downloadFunction: handleDownload,
    },
  ];

  return  (
    <>
      <div className={ classNames(styles.DownloadAppsStep, 'd-flex') }>
        { cardData.map((card) => (
          <BasicCard
            key={ card.key }
            className={ styles.basicCard }
            cardKey={ card.key }
          >
            <FlexBox flexDirection={ 'column' } id="header" className={ styles.basicCardHeader }>
              <Image src={ card.imageSrc } alt={ card.title } width={ 105 } height={ 104 } />
              <h5 className="title ft-size-16 color-pure-black txt-left">
                { t(card.title) }
              </h5>
            </FlexBox>
            <FlexBox flexDirection={ 'column' } id="content" className={ styles.basicCardContent }>
              <ul>
                { card.features.map((feature, index) => (
                  <li key={ index }>
                    <CheckIcon sx={{ color: 'var(--color-secondary-brown)', fontSize: 24 }} />
                    <span>{ t(feature) }</span>
                  </li>
                )) }
              </ul>
            </FlexBox>
            <FlexBox
              flexDirection={ 'column' }
              justifyContent={ 'flex-end' }
              id={ 'footer' }
              className={ styles.basicCardFooter }
            >
              <button style={{ ...card.buttonStyle }} onClick={ card.downloadFunction }>
                { t(card.buttonText).toUpperCase() }
              </button>
            </FlexBox>
          </BasicCard>
        )) }
      </div>
      <FlexBox
        flexDirection={ 'row' }
        alignItems={ 'center' }
        id={ 'skip-button' }
      >
        <RemindMeLaterBtn onClick={ handleSkipBtnClick }>
          { t('onboarding:invite_members_step.skip_btn') }
        </RemindMeLaterBtn>
      </FlexBox>
    </>
  );
};

const RemindMeLaterBtn = styled((props: ButtonProps) =>
  <Button variant="text" color="info" { ...props } />)(() => css`
  display: block;
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
`);
