import React, { FC, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useFeature } from '@growthbook/growthbook-react';
import { Box } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';

import { MagicLinkInviteSimplified } from '@/components/MagicLinkInvite';
import { InviteOptionsProps, InviteStepCtx } from '@/components/Steps';
import { AddMoreButton } from '@/components/Steps/InviteTeamStep/AddMoreBtn';
import { useOnboardingTask, useTracking } from '@/hooks';
import { Button, ButtonProps, LoadingButton } from '@/shared/Button';
import { Divider } from '@/shared/Divider';
import { FlexBox } from '@/shared/FlexBox';
import { Tooltip } from '@/shared/Tooltip';
import { ExperimentProps } from '@/types/tracking.types';

import { useDownloadApps } from '../../../hooks/useDownloadApps';

export const InviteOptions: FC<InviteOptionsProps> = ({
  flow,
  membersToInvite,
  onAddMoreClick,
  onSendInvites,
  renderMemberField,
  experimentVariant,
}) => {
  const { t } = useTranslation('onboarding');
  const { goToNextStep } = useOnboardingTask(flow);
  const { didCopyLink } = useContext(InviteStepCtx);
  const { trackOnboardingStep } = useTracking(flow);
  const { formState: { isSubmitting, isValid, isDirty }} = useFormContext();
  const showHybridInvitePage = useFeature<ExperimentProps>('invite-page-onboarding');
  const hybridInvitePageExp = showHybridInvitePage.value?.variationid as 'A' | 'B' | undefined;
  const { executeBrowserPromoTracking } = useDownloadApps(flow);

  const handleSkipBtnClick = async () => {
    await executeBrowserPromoTracking();
    await trackOnboardingStep('Skipped Invite User during Onboarding');
    goToNextStep();
  };


  return (
    <form
      className="d-flex-col"
      onSubmit={ onSendInvites } noValidate
      aria-label="Invite team by email"
      style={{ height: '100%', rowGap: '36px' }}
    >
      <Box>
        <FlexBox column rowGap={ 3 } mb={ 2 }>
          {
            membersToInvite.map((member, i) => renderMemberField(member, i, hybridInvitePageExp))
          }
        </FlexBox>
        <AddMoreButton
          onClick={ onAddMoreClick }
          variant="exp"
          buttonText={ experimentVariant === 'A' ? 'current' : 'exp' }
        />
      </Box>

      <Box
        display="flex" justifyContent="space-between" mt="auto" flexDirection={{ zero: 'column', sm: 'row' }}>

        <MagicLinkInviteSimplified />

        <Divider sx={{ display: { sm: 'none' }}} mt={ 2 } mb={ 2 } lineColor="info.lighter" />

        <Box display="flex" gap={ 2 } flexDirection={{ zero: 'column-reverse', sm: 'row' }}>

          { experimentVariant === 'A' && (
            <RemindMeLaterBtn onClick={ handleSkipBtnClick }>
              { t('onboarding:invite_members_step.skip_btn') }
            </RemindMeLaterBtn>
          ) }
          <Tooltip
            title={ t('onboarding:invite_members_step.tooltip_hover_btn.message') }
            disableFocusListener
            disableHoverListener={ (isValid && isDirty) || didCopyLink }
            enterTouchDelay={ 0 }
          >
            <Box flex="1 1 auto">
              <LoadingButton
                variant="contained"
                type="submit"
                disableElevation={ false }
                loading={ isSubmitting }
                disabled={ (!isDirty || !isValid) && !didCopyLink }
                fullWidth
              >
                { t('common:next') }
              </LoadingButton>
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </form>
  )
  ;
};

const RemindMeLaterBtn = styled((props: ButtonProps) =>
  <Button variant="text" color="info" { ...props } />)(() => css`
  display: block;
  flex: 1 1 auto;
`);
