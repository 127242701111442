import { FC, useContext, useEffect, useRef, useState } from 'react';
import MuiInsertLinkIcon from '@mui/icons-material/InsertLink';
import useTranslation from 'next-translate/useTranslation';

import { InviteStepCtx } from '@/components/Steps';
import { useAppSelector } from '@/hooks';
import { selectCompanyId } from '@/redux/features/authSlice';
import CompanyRequests from '@/requests/company.requests';
import { Button } from '@/shared/Button';
import { Snackbar } from '@/shared/Snackbar';

import { useClipboard } from '../../hooks/useClipboard';

type SnackbarState = {
  open: boolean;
  message: string;
}

export const MagicLinkInvSimplifiedComponent: FC = () => {
  const { copyToClipboard } = useClipboard();
  const { t } = useTranslation();
  const { setDidCopyLink } = useContext(InviteStepCtx);

  const { company } = useAppSelector(state => state.auth);
  const companyId = useAppSelector(selectCompanyId);
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({ open: false, message: '' });
  const hasTracked = useRef(false);

  useEffect(() => {
    if (!hasTracked.current) {
      // If this component was rendered, it means that the user was exposed to the Magic Link EXP
      CompanyRequests.putCompanySettings(companyId, { custom: { hasInviteLink: true }});
      hasTracked.current = true;
    }
  }, []);

  const handleCopyLink = async () => {
    setDidCopyLink(true);
    const { success } = await copyToClipboard(company?.inviteLink || '');
    const message = success
      ? t('onboarding:invite_members_step.magic_link.link_copied')
      : t('onboarding:invite_members_step.magic_link.link_not_copied');
    setSnackbarState({ open: true, message });
  };

  const handleCloseSnackbar = () => {
    setSnackbarState((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <>
      <Button
        variant="outlined"
        size="medium"
        uppercase
        onClick={ handleCopyLink }
        trackEvent={ 'Copied url on Invite Screen' }
        trackProps={{ invite_url: company?.inviteLink }}
        startIcon={ <MuiInsertLinkIcon color="primary" /> }
      >
        { t('onboarding:invite_members_step.magic_link.copy_invite_link') }
      </Button>
      <Snackbar
        open={ snackbarState.open }
        onClose={ handleCloseSnackbar }
        message={ snackbarState.message }
      />
    </>
  );
};
