import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useOnboardingTask, useTracking, useTraits } from '@/hooks';
import { selectCompanyId } from '@/redux/features/authSlice';
import CompanyRequests from '@/requests/company.requests';
import { FeaturesCard, FeaturesCardProps } from '@/shared/FeaturesCard';

import { FocusAreaStep, StepCommonProps, StepInfo } from '../../../types';

import styles from './FocusAreaStep.module.scss';

const focusAreas: FeaturesCardProps[] = [
  {
    cardKey: 'productivity',
    title: 'Productivity',
    color: '#C8FFE2',
    features: [
      'Get real time performance',
      'Minimize idle or unproductive time',
      'Monitor who is actively working',
    ],
  },
  {
    cardKey: 'wellness',
    title: 'Wellness',
    color: '#FAE0FF',
    features: [
      'Boost employee retention',
      'Prevent overwork and burnout issues',
      'Promote work-life balance',
    ],
  },
  {
    cardKey: 'timetrackingattendance',
    title: 'Time Tracking & Attendance',
    color: '#FFFACE',
    features: [
      'Track worked hours',
      'Detect lateness or absenteeism',
      'Keep your remote team accountable',
    ],
  },
  {
    cardKey: 'payroll',
    title: 'Payroll & Billing',
    color: '#C7DDFF',
    features: [
      'Get accurate timesheets',
      'Sync data with payroll',
      'Reduce manual errors',
    ],
  },
];

const FocusAreaStepComponent: FC<StepCommonProps> = ({ flow }) => {
  const { getNextStep, goToNextStep } = useOnboardingTask(flow);
  const { trackOnboardingStep } = useTracking(flow);
  const { updateTraits } = useTraits();
  const companyId = useSelector(selectCompanyId);

  const onCardClicked = useCallback((cardTitle: string) => {
    const nextStep = getNextStep();

    updateTraits({
      companyTraits: { onboarding_step: nextStep?.stepName },
      userTraits: { lead_pain_point: cardTitle, custom: { lead_pain_point: cardTitle }},
    });

    CompanyRequests.putCompanySettings(companyId, {
      custom: { painpoint: cardTitle },
    });

    trackOnboardingStep('Pain Point Selected Option', {
      painpoint: cardTitle,
    });

    goToNextStep();
  }, [companyId, getNextStep, goToNextStep, trackOnboardingStep, updateTraits]);

  const renderedFocusAreas = useMemo(() =>
    focusAreas.map(({ title, cardKey, color, features }) => (
      <FeaturesCard
        key={ cardKey }
        cardKey={ cardKey }
        title={ title }
        features={ features }
        color={ color }
        onClick={ () => onCardClicked(title) }
      />
    )),
  [onCardClicked]);

  return (
    <div className={ styles.FocusAreaStep }>
      { renderedFocusAreas }
    </div>
  );
};

export const FocusAreaStepInfo: StepInfo<FocusAreaStep> = {
  Component: FocusAreaStepComponent,
  id: 'companyInfo2Step',
  stepName: 'Challenge Screen',
  title: 'onboarding:focus_area_step.title',
  desc: 'onboarding:focus_area_step.desc',
};
