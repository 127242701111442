import { FC, useState } from 'react';
import { css } from '@emotion/react';
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';

import { InviteFormComponent, InviteStepCtx } from '@/components/Steps/InviteTeamStep/InviteFormComponent';
import { useOnboardingTask, useTracking } from '@/hooks';
import { FlowModel } from '@/models/flow.model';
import { BasicCard } from '@/shared/BasicCard';
import { FlexBox } from '@/shared/FlexBox';

import styles from './InviteSelection.module.scss';

interface InviteSelectionProps {
  flow: FlowModel;
  experimentVariant?: string;
}

const stepData = [
  {
    key: 'invite-users',
    title: 'Invite users now',
    desc: 'Unlock the full power of Time Doctor by inviting your team to Time Doctor.',
  },
  {
    key: 'on-my-own',
    title: 'Start on your own',
    desc: 'Invite the team later. Track time, collaborate, and manage workflows seamlessly.',
  },
];

const InviteStepProvider = InviteStepCtx.Provider;

export const InviteSelectionComponent: FC<InviteSelectionProps> = ({ flow, experimentVariant }) => {
  const { goToNextStep } = useOnboardingTask();
  const { t } = useTranslation();
  const [didCopyLink, setDidCopyLink] = useState<boolean>(false);
  const { trackAppEvent } = useTracking();
  const [showInviteForm, setShowInviteForm] = useState(false);
  const [isInviteUsersSelected, setIsInviteUsersSelected] = useState<boolean>(false);

  // State to track the clicked card
  const [selectedCard, setSelectedCard] = useState<string | null>(null);

  const onCardClicked = (cardKey: string) => {
    setSelectedCard(cardKey); // Store clicked card

    if (cardKey === 'invite-users') {
      if (isInviteUsersSelected) return; // Prevent clicks after "Invite Users" is selected
      onInviteUsersSelected();
    } else {
      onStartOnYourOwnSelected();
    }
  };

  const onInviteUsersSelected = () => {
    trackAppEvent('Clicked on Invite Users Now');
    setShowInviteForm(true);
    setIsInviteUsersSelected(true);
  };

  const onStartOnYourOwnSelected = () => {
    trackAppEvent('Clicked on Start On Your Own');
    goToNextStep();
  };

  return (
    <>
      <div className={ classNames(styles.InviteSelectionStep, 'd-flex') }>
        { stepData.map((data) => {
          const isSelected = selectedCard === data.key && data.key === 'invite-users';
          const isDisabled = isInviteUsersSelected && data.key === 'invite-users';

          return (
            <BasicCard
              key={ data.key }
              cardKey={ data.key }
              onCardClicked={ () => !isDisabled && onCardClicked(data.key) }
              aria-label={ data.title }
              css={ css`
                transition: all 0.3s ease-in-out;
                cursor: ${ isDisabled ? 'default !important' : 'pointer' };
                ${ isSelected
              ? `
                    box-shadow: none;
                    border: 1px solid #27C26C;
                    border-radius: 4px;
                    &:hover {
                      background-color: #FFF !important;
                    }
                  `
              : `` /* Non-clicked cards retain default styles */ };
              ` }
            >
              <h5 className="title | ft-size-16 color-pure-black txt-left">{ t(data.title) }</h5>
              <p className="subtitle | ft-size-12 color-secondary-brown">{ t(data.desc) }</p>
            </BasicCard>
          );
        }) }
      </div>
      <FlexBox flexDirection={ 'column' }>
        { showInviteForm && (
          <InviteStepProvider value={{ didCopyLink, setDidCopyLink }}>
            <hr
              css={ css`
            height: 1px;
            padding: 0;
            border: 1px solid #5748411a;
            width: 100%;
            margin-bottom: 35px;
            margin-top: 0;
          ` }
            />
            <FlexBox flexDirection={ 'column' } width={ '100%' }>
              <InviteFormComponent flow={ flow } experimentVariant={ experimentVariant } />
            </FlexBox>
          </InviteStepProvider>
        ) }
      </FlexBox>
    </>
  );
};
