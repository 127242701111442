import { useSelector } from 'react-redux';
import { useGrowthBook } from '@growthbook/growthbook-react';
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';

import { useAppSelector, useOnboardingTask, useTracking, useTraits } from '@/hooks';
import { selectCompanyId } from '@/redux/features/authSlice';
import CompanyRequests from '@/requests/company.requests';
import { BasicCard } from '@/shared/BasicCard';
import { CompanyTrackingMode } from '@/types/company.type';

import { InviteTeamStepInfo } from '../index';

import styles from './ComputerOwnerStep.module.scss';


type TrackingTraitsOpts = {
  ownership: 'company' | 'Team Members';
  reason: string;
  skipTrackingTypeScreen?: boolean;
  taskMode?: 'preset' | 'off';
  trackingType?: CompanyTrackingMode;
}

const stepData = [
  {
    key: 'company',
    title: 'onboarding:computer_owner_step.company_owner.title',
    desc: 'onboarding:computer_owner_step.company_owner.desc',
  },
  {
    key: 'team-members',
    title: 'onboarding:computer_owner_step.team-member_owner.title',
    desc: 'onboarding:computer_owner_step.team-member_owner.desc',
  },
];

export const ComputerOwnerStepComponent = () => {
  const { t } = useTranslation();

  const { company } = useAppSelector(state => state.auth);
  const { isEUMember: isEuMemberState, trackingMode } = useAppSelector(state => state.onboarding);
  const { goToNextStep, getNextStep } = useOnboardingTask();
  const { trackOnboardingStep, trackAppEvent } = useTracking();
  const { updateTraits } = useTraits();
  const companyId = useSelector(selectCompanyId);
  const growthBook = useGrowthBook();

  const onCardClicked = (cardKey: string) => {
    if (cardKey === 'company') {
      onCompanySelected();
    } else {
      onTeamMemberSelected();
    }
  };

  const handleTrackingAndTraitsUpdate = ({
    skipTrackingTypeScreen,
    ownership,
    reason,
    taskMode,
    trackingType,
  }: TrackingTraitsOpts) => {
    const nextOnboardingStep = skipTrackingTypeScreen ? InviteTeamStepInfo.stepName : getNextStep()?.stepName;

    trackOnboardingStep('Ownership Selected Option', { ownership, trackingtype: trackingType ?? trackingMode });
    if (skipTrackingTypeScreen) trackAppEvent('Skipped Tracking Type Screen', { reason, trackingtype: 'interactive' });

    updateTraits({
      companyTraits: {
        onboarding_step: nextOnboardingStep,
        ...(taskMode && { taskMode }),
      },
    });
  };

  const onCompanySelected = async () => {
    const isEuMember = company?.companySettings?.custom?.isUEMember || isEuMemberState;
    const withNextStepId = isEuMember ? InviteTeamStepInfo.id : undefined;

    handleTrackingAndTraitsUpdate({
      skipTrackingTypeScreen: isEuMember,
      ownership: 'company',
      reason: 'Company Ownership GDPR',
    });

    goToNextStep({ withNextStepId });
  };

  const onTeamMemberSelected = async () => {
    const isEuMember = company?.companySettings?.custom?.isUEMember || isEuMemberState;
    trackOnboardingStep('Tracking Type Selected Option', {
      trackingtype: 'interactive',
      ownership: 'Team Members',
    });
    handleTrackingAndTraitsUpdate({
      skipTrackingTypeScreen: true,
      ownership: 'Team Members',
      reason: isEuMember ? 'Team Member Ownership GDPR' : 'Team Member Ownership NonGDPR',
      taskMode: 'preset',
      trackingType: 'interactive',
    });
    CompanyRequests.putCompanySettings(companyId, { trackingMode: 'interactive', tasksMode: 'preset' });

    growthBook?.setAttributes({
      ...growthBook?.getAttributes(),
      tracking_mode: 'interactive',
    });

    goToNextStep({ withNextStepId: InviteTeamStepInfo.id });
  };

  return (
    <div className={ classNames(styles.ComputersStep, 'd-flex') }>
      {
        stepData.map(data => (
          <BasicCard key={ data.key } cardKey={ data.key } onCardClicked={ onCardClicked } aria-label={ data.title }>
            <h5 className="title | ft-size-16 color-pure-black txt-left">{ t(data.title) }</h5>
            <p className="subtitle | ft-size-12 color-secondary-brown">{ t(data.desc) }</p>
          </BasicCard>
        ))
      }
    </div>
  );
};
