import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import classNames from 'classnames';
import Image from 'next/image';

import styles from './FeaturesCard.module.scss';

export type FeaturesCardProps = {
  cardKey: string;
  title: string;
  color: string;
  features: string[];
} & React.AllHTMLAttributes<HTMLButtonElement>

export const FeaturesCard: React.FC<FeaturesCardProps> = (
  { cardKey, title, features, color, ...props },
) => (
  <button className={ classNames(styles.FeaturesCard, 'card') } onClick={ props.onClick } aria-label={ title }>
    <div className={ styles.FeaturesCard__Header }>
      <figure className={ styles.FeaturesCard__Header__IconWrap } style={{ background: color }}>
        <Image
          src={ `/assets/onboarding/st-${ cardKey }.svg` }
          className={ styles.FeaturesCard__Header__IconWrap__Img }
          width={ 24 } height={ 24 } alt="" />
      </figure>
      <span className={ styles.FeaturesCard__Header__Title }>{ title }</span>
    </div>
    <ul className={ styles.FeaturesCard__List }>
      { features.map((feature, index) => (
        <li key={ `features-${ cardKey }-${ index }` } className={ styles.FeaturesCard__List__ItemWrap }>
          <CheckIcon sx={{ color: 'var(--color-secondary-brown)', fontSize: 18 }} />
          <span className={ styles.FeaturesCard__List__ItemWrap__LiText }>{ feature }</span>
        </li>
      )) }
    </ul>
  </button>
);
